<template>
    <header class="page-title">
        <h1><i class="bi bi-key-fill"></i> システム管理 - スプレッドシート同期</h1>
    </header>

    <section class="section">
        <div class="mb-3 alert alert-warning">処理待ちジョブ : {{ queued }}</div>
        <div class="mb-3 alert alert-danger">24時間以内の失敗ジョブ : {{ failed }}</div>
        <div class="mb-3"><button class="btn btn-lg btn-outline-info" @click="resync()">全案件再同期を予約</button></div>
        <div>
            予約の完了に数分かかります。ボタンを押してから、予約の完了まで、画面を閉じないでください。<br>
            その後、再同期の完了には6〜8時間程度かかります。個別の案件・活動の更新処理は、その完了後に順次反映されます。
        </div>
    </section>

</template>

<script>
export default {
    name: 'SystemSpreadsheet',
    components: {
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            queued: "取得中",
            failed: "取得中",
        }
    },
    mounted() {
        this.$http.get('/spreadsheet')
            .then(response => {
                this.queued = response.data.queued + " 件";
                this.failed = response.data.failed + " 件";
            });
    },
    methods: {
        // 再同期実行
        resync() {
            this.startScreenLoading();

            this.$http.post('/spreadsheet/resync')
            .then(response => {
                this.queued = response.data.queued + " 件";
                this.failed = response.data.failed + " 件";
                this.showMessage('再同期を予約しました');
            })
            .finally(() => {
                this.endScreenLoading();
            })
        }
    }
}
</script>

<style scoped>

</style>
